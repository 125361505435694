const fadeIn = () => {

    const items = document.querySelectorAll('.fade-in');

    const options = {
        root: null, // if its null then its window
        threshold: 0, // when its 25% inside screen
        rootMargin: "0px 0px -200px  0px"
    };
    
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                return;
            };
            entry.target.classList.add('show');
            observer.unobserve(entry.target);
        });
    }, options);
    
    items.forEach(item => {
        observer.observe(item);
    });
    
};

export default fadeIn;
