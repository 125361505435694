const cardsSwitch = () => {
  const navItems = document.querySelectorAll(".hosting-cards__nav-item");
  const cardBoxes = document.querySelectorAll(".hosting-cards__box");

  navItems.forEach((el, index) => {
    el.addEventListener("click", () => {
      navItems.forEach((item) => {
        item.classList.remove("active");
      });
      cardBoxes.forEach((item) => {
        item.classList.remove("active");
      });
      navItems[index].classList.add("active");
      cardBoxes[index].classList.add("active");
    });
  });

  const navItems2 = document.querySelectorAll(".accordion__nav-item");
  const cardBoxes2 = document.querySelectorAll(".accordion__box");

  navItems2.forEach((el, index) => {
    el.addEventListener("click", () => {
      navItems2.forEach((item) => {
        item.classList.remove("active");
      });
      cardBoxes2.forEach((item) => {
        item.classList.remove("active");
      });
      navItems2[index].classList.add("active");
      cardBoxes2[index].classList.add("active");
    });
  });
};

export default cardsSwitch;
