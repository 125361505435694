const checkQuery = () => {
  if (!document.querySelector(".accordion")) return;
  const section = parseInt(window.location.search[3]);

  if (!section) return;

  const navItems2 = document.querySelectorAll(".accordion__nav-item");
  const cardBoxes2 = document.querySelectorAll(".accordion__box");

  navItems2.forEach((item) => {
    item.classList.remove("active");
  });
  cardBoxes2.forEach((item) => {
    item.classList.remove("active");
  });
  navItems2[section - 1].classList.add("active");
  cardBoxes2[section - 1].classList.add("active");
};

export default checkQuery;
