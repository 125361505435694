import $, {jQuery} from 'jquery';
import select2 from 'select2';
select2($);

const select = () => {
    $('.domain-search__select').select2({
        minimumResultsForSearch: -1
    });
}

export default select;