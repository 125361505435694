const slider = () => {
  const slideBackgrouns = document.querySelectorAll(".home-banner-picture");
  const slideTexts = document.querySelectorAll(".home-banner__content-slide");
  const slideNavs = document.querySelectorAll(".home-banner__item");

  slideNavs.forEach((nav, index) => {
    nav.addEventListener("click", () => {
      slideBackgrouns.forEach((bg) => {
        bg.classList.remove("active");
      });
      slideTexts.forEach((text) => {
        text.classList.remove("active");
      });
      slideNavs.forEach((item) => {
        item.classList.remove("active");
      });
      slideBackgrouns[index].classList.add("active");
      slideTexts[index].classList.add("active");
      slideNavs[index].classList.add("active");
    });
  });
};

export default slider;
