const table = () => {
  var subsCard = document.querySelector(".js-table-box");
  var subsTable = document.querySelector(".js-table");
  if (!subsTable) return;
  dragElement();
  var currentLeft = 0;
  var cursorPosition = 0;

  function dragElement() {
    subsTable.onmousedown = dragMouseDown;
    subsTable.addEventListener("touchstart", function (e) {
      e = e || window.event;
      cursorPosition = e.clientX || e.touches[0].clientX;
    });
    subsTable.addEventListener("touchmove", function (e) {
      elementDrag(e);
    });
    subsTable.addEventListener("touchend", function (e) {
      closeDragElement();
    });

    function dragMouseDown(e) {
      e = e || window.event;
      cursorPosition = e.clientX || e.touches[0].clientX;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
      document.touchend = closeDragElement;
    }

    function elementDrag(e) {
      if (window.innerWidth > 1400) {
        return;
      }
      e = e || window.event;
      var newCursorPosition = e.clientX || e.touches[0].clientX;
      var positionDifference = cursorPosition - newCursorPosition;
      currentLeft = parseInt(currentLeft);
      var cardMargin = window.innerWidth <= 767 ? 0 : 0;
      if (currentLeft - positionDifference >= 0) {
        subsTable.style.left = "0px";
      } else if (
        currentLeft - positionDifference <=
        -(subsTable.offsetWidth - subsCard.offsetWidth + cardMargin)
      ) {
        subsTable.style.left =
          -(subsTable.offsetWidth - subsCard.offsetWidth + cardMargin) + "px";
      } else {
        subsTable.style.left = currentLeft - positionDifference + "px";
      }
    }

    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      currentLeft = subsTable.style.left;
    }
  }
  window.addEventListener("resize", function () {
    subsTable.style.left = "0px";
    currentLeft = 0;
  });
};

export default table;
