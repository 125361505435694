const question = () => {
    const questions = document.querySelectorAll('.question');

    class Question {
        constructor(el) {
            this.el = el;
            this.top = el.querySelector('.question__top')
            this.bottom = el.querySelector('.question__bottom')
            this.bottomInner = el.querySelector('.question__bottom-hidden')
            this.open = false
            this.handleOpen();
        }

        handleOpen() {
            this.top.addEventListener('click', ()=> {
                if(!this.open) {
                    this.open = true;
                    this.bottom.style.height = `${this.bottomInner.getBoundingClientRect().height}px`
                    this.top.classList.add('open')
                } else {
                    this.open = false;
                    this.bottom.style.height = '0px'
                    this.top.classList.remove('open')
                }
            })
        }
    }

    questions.forEach(question => {
        const q = new Question(question);
    })
}

export default question;