import gsap, { Elastic, Circ } from "gsap";

const header = () => {
  const header = document.querySelector(".header");
  window.addEventListener("scroll", () => {
    if (window.scrollY > 5) {
      header.classList.add("scroll");
    } else {
      header.classList.remove("scroll");
    }
  });

  const mobileMenu = document.querySelector(".navigation");
  const hamburger = document.querySelector(".hamburger");
  const lineOne = document.querySelector(".hamburger-one");
  const lineTwo = document.querySelector(".hamburger-two");
  const lineThree = document.querySelector(".hamburger-three");

  const menuTimeline = gsap.timeline({ paused: true, reversed: true });

  menuTimeline.to(lineOne, { top: "7px", duration: 0.2 });
  menuTimeline.to(lineTwo, { opacity: 0, duration: 0.2 }, "-=0.2");
  menuTimeline.to(lineThree, { bottom: "7px", duration: 0.2 }, "-=0.2");
  menuTimeline.to(lineOne, {
    transform: "rotate(-45deg)",
    duration: 0.3,
    ease: Elastic.easeOut.config(2.5, 1),
  });
  menuTimeline.to(
    lineThree,
    {
      transform: "rotate(45deg)",
      ease: Elastic.easeOut.config(2.5, 1),
      duration: 0.3,
    },
    "-=0.3"
  );
  menuTimeline.to(
    mobileMenu,
    {
      x: 0,
      duration: 0.8,
      ease: Circ.easeInOut,
    },
    "0"
  );

  const main = document.querySelector(".main");

  hamburger.addEventListener("click", () => {
    if (
      mobileMenu.getAttribute("data-menu-open") === "close" ||
      !mobileMenu.getAttribute("data-menu-open")
    ) {
      mobileMenu.setAttribute("data-menu-open", "open");
      menuTimeline.play();
    } else {
      mobileMenu.setAttribute("data-menu-open", "close");
      menuTimeline.reverse();
    }
  });
};

export default header;
