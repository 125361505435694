import "intersection-observer";
import fadeIn from "./components/fadeIn";
import header from "./components/header";
import question from "./components/question";
import select from "./components/select";
import cardsSwitch from "./components/cardsSwitch";
import table from "./components/table";
import slider from "./components/slider";
import checkQuery from "./components/checkQuery";

fadeIn();
header();
question();
select();
cardsSwitch();
table();
slider();
checkQuery();
